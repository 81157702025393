<script>
    export let mode;
    export let formID;

    import { slide } from 'svelte/transition';
    import { service as positionService } from '@/services/position';

    import Time from '@/icons/Time.svelte';
    import License from '@/icons/License.svelte';
    import Calendar from '@/icons/Calendar.svelte';
    import Stroke from '@/icons/Stroke.svelte';

    import Input from '@/components/form/Input.svelte';
    import AutocompleteInput from '@/components/form/AutocompleteInput.svelte';
    import Textarea from '@/components/form/Textarea.svelte';
    import Select from '@/components/form/Select.svelte';
    import Upload from '@/components/form/Upload.svelte';
    import ReorderUploads from '@/components/form/ReorderUploads.svelte';
    import Contenteditable from '@/components/form/Contenteditable.svelte';
    import Information from '@/icons/Information.svelte';
    import Slider from '@/components/Slider.svelte';

    import { formatDate, newlinesToArray, newlinesToBreak, getAvailableFileSize } from '@/lib/utils';

    import { jobAdvertisement, employer as tempEmployer } from "@/stores/temporary";
    import { service as sectorService, sectors } from "@/services/sector";
    import { onMount } from 'svelte';

    onMount(() => {
        if(mode === "edit") sectorService.index();
    });

    const handleChangeWorkingTime = (e) => {
        if($jobAdvertisement.data.working_time !== e.detail.detail) {
            $jobAdvertisement.data.position = undefined;
        }
        $jobAdvertisement.data.working_time = e.detail.detail;
    }

    let files = $jobAdvertisement?.data?.files?.data;
</script>

<div transition:slide>
    <div class="container-fluid">
        <section class="py-6 grid grid-cols-3 gap-2 max-w-lg mx-auto px-3">
            <div class="shadow-xl shadow-earth/10 rounded-3xl {mode === "edit" ? "rounded-b-xl" : ""} bg-white">
                <div class="px-1 inline-flex flex-col gap-1 items-center w-full {mode === "edit" ? "pt-3 pb-1" : "py-3"}">
                    <Time mainClass="text-limegreen h-12 w-12"/>
                    <p class="leading-none text-xs mt-0.5">
                        Pensum:
                    </p>
                    {#if mode === "view"}
                        <p class="text-earth font-bold leading-none text-sm mt-1">
                            {$jobAdvertisement.data.working_time ?? "Keine Angabe"}
                        </p>
                    {:else if mode === "edit"}
                        <Select
                            value={$jobAdvertisement.data.working_time}
                            name="working_time"
                            placeholder="Pensum"
                            mainClass="!bg-poisongreen/20 !border-limegreen"
                            containerClass="small-select editmode-select mt-1 w-full"
                            required={true}
                            clearable={false}
                            showChevron={true}
                            items={[
                                {id: "Vollzeit", name: "Vollzeit"},
                                {id: "Teilzeit", name: "Teilzeit"},
                                {id: "Voll-/ Teilzeit", name: "Voll-/ Teilzeit"},
                                {id: "Ausbildung", name: "Ausbildung"},
                                {id: "Saisonarbeit", name: "Saisonarbeit"},
                            ]}
                            label="name"
                            itemId="id"
                            multiple={false}
                            {formID}
                            on:change={(e) => handleChangeWorkingTime(e)}
                            on:input={(e) => handleChangeWorkingTime(e)}
                        >
                            <div slot="item" let:item>
                                {item.name}
                            </div>
                        </Select>
                    {/if}
                </div>
            </div>
            <div class="shadow-xl shadow-earth/10 rounded-3xl {mode === "edit" ? "rounded-b-xl" : ""} bg-white">
                <div class="px-1 inline-flex flex-col gap-1 items-center w-full {mode === "edit" ? "pt-3 pb-1" : "py-3"}">
                    <License mainClass="text-limegreen h-12 w-12"/>
                    <p class="leading-none text-xs mt-0.5">
                        Vertrag:
                    </p>
                    {#if mode === "view"}
                        <p class="text-earth font-bold leading-none text-sm mt-1">
                            {$jobAdvertisement.data.employment_type ?? "Keine Angabe"}
                        </p>
                    {:else if mode === "edit"}
                        <Select
                            value={$jobAdvertisement.data.employment_type}
                            name="employment_type"
                            placeholder="Anstellungsart"
                            mainClass="!bg-poisongreen/20 !border-limegreen"
                            containerClass="small-select editmode-select mt-1 w-full"
                            required={true}
                            clearable={false}
                            showChevron={true}
                            items={[
                                {id: "Unbefristet", name: "Unbefristet"},
                                {id: "Befristet", name: "Befristet"},
                            ]}
                            label="name"
                            itemId="id"
                            multiple={false}
                            {formID}
                            on:change={(e) => {$jobAdvertisement.data.employment_type = e.detail.detail}}
                            on:input={(e) => {$jobAdvertisement.data.employment_type = e.detail.detail}}
                        >
                            <div slot="item" let:item>
                                {item.name}
                            </div>
                        </Select>
                    {/if}
                </div>
            </div>
            <div class="shadow-xl shadow-earth/10 rounded-3xl {mode === "edit" ? "rounded-b-xl" : ""} bg-white">
                <div class="px-1 inline-flex flex-col gap-1 items-center w-full {mode === "edit" ? "pt-3 pb-1" : "py-3"}">
                    <Calendar mainClass="text-limegreen h-12 w-12"/>
                    <p class="leading-none text-xs mt-0.5">
                        Verfügbar ab:
                    </p>
                    {#if mode === "view"}
                    <p class="text-earth font-bold leading-none text-sm mt-1">
                        {$jobAdvertisement.data.job_available_at ? formatDate($jobAdvertisement.data.job_available_at, "DD.MM.YYYY") : "Keine Angabe"}
                    </p>
                    {:else if mode === "edit"}
                        <Input
                            bind:value={$jobAdvertisement.data.job_available_at}
                            name="job_available_at"
                            placeholder="Verfügbar ab"
                            type="date"
                            {formID}
                            containerClass="w-full mt-1"
                            inputClass="!bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white !p-1 text-xs w-full h-[35px]"
                        />
                    {/if}
                </div>
            </div>
        </section>
    </div>

    <section class="py-6">
        {#if mode === "edit" && $sectors?.data}
            <Select
                value={$jobAdvertisement.data.sector_id}
                name="sector_id"
                placeholder="Branche wählen"
                mainClass="mt-2 !bg-poisongreen/20"
                containerClass="editmode-select"
                required={true}
                clearable={false}
                showChevron={true}
                items={$sectors.data}
                label="name"
                itemId="id"
                multiple={false}
                {formID}
                on:change={(e) => {$jobAdvertisement.data.sector = e.detail.detail}}
                on:input={(e) => {$jobAdvertisement.data.sector = e.detail.detail}}
            >
                <div slot="item" let:item>
                    {item.name}
                </div>
            </Select>
        {/if}

        {#if mode === "view"}
            <h1 class="text-3xl font-semibold text-moss">
                {$jobAdvertisement.data.position.name} <span class="font-light text-xl">(m/w/d)</span>
            </h1>
        {:else if mode === "edit"}
            <AutocompleteInput
                service={positionService}
                disabled={!$jobAdvertisement.data.working_time}
                serviceMethod="search"
                renderValue={(value) => value?.name ?? ""}
                dataToSend={(value) => {
                    return {
                        name: value.name,
                        working_time: $jobAdvertisement.data.working_time?.id ?? $jobAdvertisement.data.working_time
                    };
                }}
                bind:value={$jobAdvertisement.data.position}
                name="position_id"
                placeholder="Jobtitel wählen"
                type="text"
                {formID}
                containerClass="w-full my-3"
                inputClass="!bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white text-xl"
            />

            {#if !$jobAdvertisement.data.working_time}
                <div class="flex flex-row gap-2 my-2 text-limegreen sm:text-center">
                    <Information mainClass="w-5 shrink-0"/>
                    <p>
                        Um einen Jobtitel zu wählen, müssen Sie zuerst das Pensum des Jobs auswählen.
                    </p>
                </div>
            {/if}
        {/if}

        {#if mode === "view"}
            {#if $jobAdvertisement.data.description}
                <div class="mt-2">
                    {@html newlinesToBreak($jobAdvertisement.data.description)}
                </div>
            {/if}
        {:else if mode === "edit"}
            <Textarea
                bind:value={$jobAdvertisement.data.description}
                required
                name="description"
                placeholder="Beschreiben Sie die Stelle kurz und prägnant für den Bewerber (max. 250 Zeichen.)"
                type="text"
                {formID}
                containerClass="w-full my-3"
                inputClass="!bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white text-xl"
            />
        {/if}
    </section>

        {#if mode === "view" && $jobAdvertisement.data.files.data.length > 0}
            <section class="py-6 overflow-x-hidden relative">
                <Slider id={$jobAdvertisement.data.id}>
                    {#each $jobAdvertisement.data.files.data as file}
                        <div class="swiper-slide h-full flex flex-col justify-center" style="height: unset;">
                            {#if file.url.endsWith(".mp4")}
                                <video controls 
                                    class="bg-moss border-2 object-cover shadow-lg border-white rounded-xl w-full aspect-video">
                                    <source src="{file.url}" type="video/mp4"/>
                                    <track kind="captions"/>
                                </video>
                            {:else}
                                <img 
                                    src="{getAvailableFileSize(file, 'lg')}" 
                                    alt="{file.properties.originalFilename}" 
                                    class="bg-moss border-2 object-contain shadow-lg border-white rounded-xl mx-auto w-auto"
                                />
                            {/if}
                        </div>
                    {/each}
                </Slider>
            </section>
        {:else if mode === "edit"}
            <section class="py-6 overflow-x-hidden relative">
                <h2 class="text-2xl font-medium text-earth inline-flex flex-col gap-1 items-start">
                    Stellenbezogene Bilder / Videos hochladen
                    <Stroke mainClass="inline-block text-limegreen h-1 w-auto"/>
                </h2>
                <div class="mt-4">
                    <Upload
                        bind:files={$jobAdvertisement.data.files}
                        type="horizontal"
                        title="Bilder oder Videos hochladen"
                        message=" Laden Sie hier stellenbezogene Bilder und Videos hoch. Der Bewerber muss erkennen, wie sein Alltag bei Ihnen aussieht."
                        maxFileSize="512MB"
                        allowMultiple={true}
                        acceptedFileTypes={["image/jpg","image/jpeg","image/png","image/webp", "video/mp4"]}
                        formID={formID}
                        name="files"
                    />

                    <ReorderUploads 
                        files={files} 
                        resource_class_name="Employer" 
                        resource_id="{$jobAdvertisement.data.id}" 
                        collection_name="files"
                    />
                </div>
            </section>
        {/if}

    <section class="py-6">
        <h2 class="text-2xl font-medium text-earth inline-flex flex-col gap-1 items-start">
            Deine Aufgaben
            <Stroke mainClass="inline-block text-limegreen h-1 w-auto"/>
        </h2>

        <div class="mt-4">
            {#if mode === "view"}
                <div class="contenteditable">
                    <ul id="list">
                        {#each newlinesToArray($jobAdvertisement.data.your_tasks) as task}
                            <li>{task}</li>
                        {/each}
                    </ul>
                </div>
            {:else if mode === "edit"}
                <Contenteditable 
                    value="{$jobAdvertisement.data.your_tasks}" 
                    on:change="{(e) => $jobAdvertisement.data.your_tasks = e.detail}" 
                    newItemLabel="Stichpunkt hinzufügen..."
                    formID="{formID}"
                    name="your_tasks"
                />
            {/if}
        </div>
    </section>

    <section class="py-6">
        <h2 class="text-2xl font-medium text-earth inline-flex flex-col gap-1 items-start">
            Dein Profil
            <Stroke mainClass="inline-block text-limegreen h-1 w-auto"/>
        </h2>

        <div class="mt-4">
            {#if mode === "view"}
                <div class="contenteditable">
                    <ul id="list">
                        {#each newlinesToArray($jobAdvertisement.data.your_profile) as task}
                            <li>{task}</li>
                        {/each}
                    </ul>
                </div>
            {:else if mode === "edit"}
                <Contenteditable 
                    value="{$jobAdvertisement.data.your_profile}" 
                    on:change="{(e) => $jobAdvertisement.data.your_profile = e.detail}" 
                    newItemLabel="Stichpunkt hinzufügen..."
                    formID="{formID}"
                    name="your_profile"
                />
            {/if}
        </div>
    </section>
</div>