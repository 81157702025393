<script>
    import { fly } from "svelte/transition";
    import { user } from "@/services/user";
    import { onDestroy, onMount } from "svelte";
    import { success, failure } from "@/lib/toast";
    import { navigate } from "svelte-routing";
    import { getQueryParameterByName } from "@/lib/utils";
    import LoadingDots from "@/components/LoadingDots.svelte"
    import { api as checkout } from "@/services/checkout";

    let session_id = undefined;
    let intended = undefined;

    const listenChanges = () => {
        window.Echo.private(`App.Models.User.${$user.data.id}`)
            .listen("CheckoutSuccess", (data) => {
                success("Ihr Checkout war erfolgreich");
                navigate(intended);
            })
            .listen("CheckoutFailed", (data) => {
                failure("Es gab einen Fehler beim verarbeiten Ihrer Zahlung. Bitte melden Sie sich bei unserem Support-Team");
                navigate("/checkout/failed");
            });
    };

    const unlistenChanges = () => {
        window.Echo.private(`App.Models.User.${$user.data.id}`).stopListening(
            "CheckoutSuccess",
        );

        window.Echo.private(`App.Models.User.${$user.data.id}`).stopListening(
            "CheckoutFailed",
        );
    };

    onMount(() => {
        session_id = getQueryParameterByName("session_id");
        intended = getQueryParameterByName("intended");
        
        listenChanges();

        checkout.process({ session_id: session_id });
    });

    onDestroy(() => {
        unlistenChanges();
    });
</script>

<svelte:head>
  <title>Checkout - Green Careers</title>
  <meta name="description" content="Checkout"/>
</svelte:head>

<main in:fly out:fly>
    <div
        class="flex items-center justify-center min-h-screen py-24 bg-earth"
    >
        <div class="w-screen px-6 mt-4 space-y-8 md:mt-0 md:px-2 max-w-lg">
            <div
                class="p-8 space-y-4 bg-gray-700 text-white backdrop-blur-xl shadow-2xl rounded-md relative bg-sandlight"
            >
                <LoadingDots/>
                <h2 class="text-center text-moss">
                    Bestellung wird verarbeitet
                </h2>
                <p class="text-center text-limegreen">
                    Dieser Vorgang kann bis zu 15 Sekunden dauern. Sie werden danach automatisch weitergeleitet und erhalten eine Bestellbestätigung per E-Mail.
                </p>
            </div>
        </div>
    </div>
</main>