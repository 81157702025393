<script>
    export let open;
    export let data = {};

    import Popup from "@/components/Popup.svelte";
    import LoadingDots from "@/components/LoadingDots.svelte";
    import Checkbox from "@/components/form/Checkbox.svelte";
    import Select from "@/components/form/Select.svelte";
    import Input from "@/components/form/Input.svelte";

    import { objectToArray } from "@/lib/utils";
    import { employer } from "@/services/employer";
    import { api as checkoutService } from "@/services/checkout";
    import { success, failure } from "@/lib/toast";
    import { merge } from "lodash";
    import { navigate } from "svelte-routing";

    let loading = false;
    let formID = 'checkoutInvoice';

    let prices = {
        smart: {
            1: {
                price: 1349,
                discount: 0,
            },
            2: {
                price: 2429,
                discount: 10,
            },
            3: {
                price: 3239,
                discount: 20,
            },
            4: {
                price: 3779,
                discount: 30,
            },
        },
        premium: {
            1: {
                price: 2590,
                discount: 0,
            },
            2: {
                price: 4690,
                discount: 10,
            },
            3: {
                price: 6290,
                discount: 20,
            },
            4: {
                price: 7290,
                discount: 30,
            },
        },
    }

    let employerData = {
        company: $employer?.data?.company ?? "",
        street: $employer?.data?.street ?? "",
        zipcode: $employer?.data?.zipcode ?? "",
        location: $employer?.data?.location ?? "",
        country: $employer?.data?.country ?? "",
        tax_number: $employer?.data?.tax_number ?? "",
    }

    const updateEmployerTaxNumber = () => {
        if (employerData.country === "de" || employerData.country.value === "de") {
            employerData.tax_number = "";
        }
    }

    $: employerData.country && updateEmployerTaxNumber();

    const proceedCheckoutInvoice = () => {
        loading = true;

        employerData.country = employerData.country ? employerData.country.value : "";

        checkoutService.checkoutInvoice(merge({
            type: data.type,
            quantity: data.amount.id,
            terms_of_service: data.terms_of_service,
        }, employerData), {formID: formID})
            .then(() => {
                loading = false;
                open = false;
                navigate('/dashboard');
                success('Vielen Dank für Ihre Bestellung! Sie erhalten in Kürze eine E-Mail mit den Zahlungsinformationen.');
            })
            .catch(() => {
                loading = false;
                failure('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.');
            });
    }
</script>

<main>
    <Popup
        open={open}
        on:close={() => (open = false)}
    >
        {#if loading}
            <div class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                <LoadingDots/>
            </div>
        {/if}
        {#if data}
            <div class="{loading ? "opacity-40" : "opacity-100"}">
                <strong class="text-2xl text-snow">
                    Bezahlung auf Rechnung
                </strong>

                <div class="hidden lg:block">
                    <p class="mt-2">
                        Vielen Dank für Ihr Interesse an unserem Angebot! Sie haben die Möglichkeit, den Kauf bequem per Rechnung abzuschließen. Nach Abschluss der Bestellung erhalten Sie alle Zahlungsinformationen per E-Mail.
                    </p>
                </div>

                <hr class="my-4 border-sandlight/40"/>

                <table class="mt-3 -mx-2">
                    <tr>
                        <td class="px-2 py-1 font-medium">
                            Anzahl Stellen:
                        </td>
                        <td class="px-2 py-1">
                            {data.amount.id}
                        </td>
                    </tr>
                    <tr>
                        <td class="px-2 py-1 font-medium">
                            Paket:
                        </td>
                        <td class="px-2 py-1">
                            {data.type === "smart" ? 'Smart' : 'Premium'}
                        </td>
                    </tr>
                    <tr>
                        <td class="px-2 py-1 font-medium">
                            Gesamtpreis:
                        </td>
                        <td class="px-2 py-1">
                            {prices[data.type][data.amount.id].price} €
                        </td>
                    </tr>
                </table>

                <hr class="my-4 border-sandlight/40"/>

                <strong class="text-xl text-snow">
                    Rechnungsadresse
                </strong>
                <div class="hidden lg:block">
                    {#if employerData.company}
                        <p>
                            Bitte überprüfen Sie Ihre Rechnungsadresse und korrigieren Sie diese gegebenenfalls.
                        </p>
                    {:else}
                        <p>
                            Bitte geben Sie Ihre Rechnungsadresse ein.
                        </p>
                    {/if}
                </div>

                <Input
                    bind:value={employerData.company}
                    required={true}
                    name="company"
                    placeholder="Firma"
                    type="text"
                    {formID}
                    containerClass="w-full my-3"
                    inputClass="!bg-sandlight focus:!bg-white"
                />

                <Input
                    bind:value={employerData.street}
                    required={true}
                    name="street"
                    placeholder="Adresse"
                    type="text"
                    {formID}
                    containerClass="w-full my-3"
                    inputClass="!bg-sandlight focus:!bg-white"
                />

                <div class="flex flex-row gap-2">
                    <Input
                        bind:value={employerData.zipcode}
                        required={true}
                        name="zipcode"
                        placeholder="PLZ"
                        type="text"
                        {formID}
                        containerClass="w-1/2"
                        inputClass="!bg-sandlight focus:!bg-white"
                    />
                    <Input
                        bind:value={employerData.location}
                        required={true}
                        name="location"
                        placeholder="Stadt"
                        type="text"
                        {formID}
                        containerClass="w-1/2"
                        inputClass="!bg-sandlight focus:!bg-white"
                    />
                </div>

                {#if window.meta.countries}
                    <Select
                        bind:value={employerData.country}
                        required={true}
                        name="country"
                        placeholder="Land"
                        items={objectToArray(window.meta.countries)}
                        {formID}
                        label="label"
                        itemId="value"
                        containerClass="w-full my-3 light"
                        on:change={(e) => {employerData.country = e.detail.detail}}
                        on:input={(e) => {employerData.country = e.detail.detail}}
                    />
                {/if}

                {#if (employerData?.country === "de" || employerData?.country?.value === "de" || employerData?.country === "") === false}
                    <Input
                        bind:value={employerData.tax_number}
                        name="tax_number"
                        placeholder="Steuernummer"
                        type="text"
                        {formID}
                        containerClass="w-full my-3"
                        inputClass="!bg-sandlight focus:!bg-white"
                    />
                {/if}

                <hr class="my-4 border-sandlight/40"/>

                <p>
                    Klicken Sie auf „Jetzt kostenpflichtig bestellen", um den Kauf abzuschließen und die Rechnung zu erhalten.
                </p>

                <br/>
                <Checkbox
                    bind:value={data.terms_of_service}
                    name="terms_of_service"
                    formID="{formID}"
                    option="<span>Ich habe die <a class='!text-white' target='_blank' href='/agb'>AGB</a> und <a class='!text-white' target='_blank' href='/datenschutz'>Datenschutzbestimmungen</a> gelesen und akzeptiere diese.</span>"
                />

                <div class="mt-4">
                    <button on:click={proceedCheckoutInvoice} class="button gradient block text-center">
                        Jetzt kostenpflichtig bestellen
                    </button>
                </div>
            </div>
        {/if}
    </Popup>
</main>