<script>
    export let job;
    export let clickable;
    export let overwriteLink = undefined;

    import Location from '@/icons/Location.svelte';
    import Company from '@/icons/Company.svelte';
    import CompanyLogo from './CompanyLogo.svelte';
    import PremiumBadge from './PremiumBadge.svelte';
    import DateBadge from './DateBadge.svelte';

    import { navigate } from "svelte-routing"
    import { getAvailableFileSize } from '@/lib/utils'

    const getTitleImage = (size = "lg") => {
        if(job.title_images.data[0]) {
            return getAvailableFileSize(job.title_images.data[0], size)
        } else if(job.employer.title_images.data[0]) {
            return getAvailableFileSize(job.employer.title_images.data[0], size)
        } else {
            return "/images/employee-1.jpg"
        }
    }
</script>

<button 
    on:click={() => clickable ? navigate(overwriteLink ? overwriteLink : job.slug) : null} 
    class="w-full aspect-[4/2.5] bg-moss rounded-xl shadow-lg bg-cover bg-no-repeat bg-center !text-left overflow-hidden relative"
    style="background-image: url({getTitleImage("md-blurred")})">
    {#if job.is_premium}
        <PremiumBadge/>
    {/if}
    {#if job.job_available_at}
        <DateBadge date={job.job_available_at}/>
    {/if}
    <img src={getTitleImage("lg")} alt="job" class="absolute top-0 left-0 w-full h-full object-contain rounded-2xl"/>
    <div class="absolute bottom-0 left-0 right-0 p-4 z-20">
        <div class="flex flex-row gap-4 items-center text-white">
            {#if job?.employer?.logos?.data?.length > 0}
                <CompanyLogo image={job.employer.logos.data[0].thumb} mainClass="w-1/4"/>
            {:else}
                <img class="w-[50px] xl:w-[75px] aspect-square !rounded-2xl border-2 border-white shadow-xl" src="/images/logo-placeholder.png" alt="Logo Platzhalter"/>
            {/if}
            <div class="w-3/4">
                <div class="flex flex-row gap-0.5 items-center">
                    <div class="grow-1 w-auto max-w-[75%]">
                        <p class="font-bold truncate">
                            {job.position.name}
                        </p>
                    </div>
                    <p class="font-light !text-xs w-[25%] min-w-14">
                        (m/w/d)
                    </p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <Company mainClass="w-4 h-4 text-poisongreen"/>
                    <p class="line-clamp-1 text-ellipsis overflow-hidden">
                        {job.employer.company}
                    </p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <Location mainClass="w-4 h-4 text-poisongreen"/>
                    <p class="line-clamp-1 text-ellipsis overflow-hidden">
                        {job.zipcode} {job.location}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="absolute bottom-0 left-0 right-0 top-0 bg-gradient-to-b from-black/10 to-black/50 z-0 w-full rounded-b-xl"></div>
</button>