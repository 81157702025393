<script>
    import { onMount, createEventDispatcher } from "svelte";

    import LogoSlider from "@/components/LogoSlider.svelte";
    import Path from "@/components/Path.svelte";
    import EmployerTestimonialSlider from "@/components/EmployerTestimonialSlider.svelte";
    import PricingSlider from "@/components/PricingSlider.svelte";
    import Call from "@/icons/Call.svelte";
    import Mail from "@/icons/Mail.svelte";
    import Stroke from "@/icons/Stroke.svelte";
    import TypeWriter from "@/components/TypeWriter.svelte";

    const dispatch = createEventDispatcher();

    onMount(() => {
        dispatch("loaded")
    })
</script>

<svelte:head>
  <title>Für Arbeitgeber - Green Careers</title>
  <meta name="description" content="Jetzt die besten grünen Talente finden. Das Karrierenetzwerk für die grüne Branche."/>
</svelte:head>

<main>
    <section class="appear min-h-[var(--app-height)] fill-available md:min-h-[650px] bg-cover bg-[65%] lg:bg-center relative" style="background-image: url('/images/employee-1.jpg');">
        <div class="absolute left-0 right-0 bottom-0 top-24 bg-gradient-to-t from-black/90"></div>
        <div class="absolute left-0 right-0 bottom-0 py-12">
            <div class="container mx-auto max-w-5xl">
                <span class="text-poisongreen text-sm lg:text-xl font-medium italic" style="text-shadow: rgba(0,0,0,0.5) 2px 2px 10px;">
                    Jetzt die besten grünen Talente finden.
                </span>
                <h1 class="text-white text-2xl sm:text-3xl lg:text-6xl">
                    Das Karrierenetzwerk für die grüne Branche.
                </h1>
                <h2 class="inline-block relative pl-1 ml-1 font-lemon text-white mt-1 text-2xl lg:text-3xl">
                    <TypeWriter texts={['GaLaBau', 'Pflanzenhandel', 'Kultivierung', 'Landwirtschaft']} mainClass="z-10 relative"/>
                    <div class="bg-limegreen backdrop-blur-md shadow-md absolute -top-1 -left-1 -right-3 -bottom-3 z-0 rotate-[5deg] -skew-y-6 transition-all ease-in-out"></div>
                </h2>
                <div class="mt-12 max-w-sm">
                    <button on:click={() => document.getElementById("unsere-pakete").scrollIntoView()} class="button gradient !rounded-full">Jetzt starten</button>
                </div>
            </div>
        </div>
    </section>

    <div class="container mx-auto lg:max-w-4xl html-content">
        <section class="py-12 overflow-x-hidden appear container-fluid relative">
            <div class="container mx-auto">
                <h2 class="text-moss text-center">
                    Ein Auszug unserer Partner
                </h2>
                
                <LogoSlider/>
            </div>
        </section>

        <section class="-my-8 lg:my-0 appear">
            <Path/>
        </section>

        <section class="-mt-28 lg:-mt-48 pb-12 overflow-x-hidden appear relative z-20">
            <img src="/images/employee-2.jpg" alt="" class="bg-moss border-2 shadow-lg border-white object-cover object-center rounded-md w-full aspect-video"/>
        </section>

        <section class="py-12 overflow-x-hidden appear relative z-10">
            <h2 class="text-moss md:text-center">
                Wieso sollten Sie auf GreenCareers setzen?
            </h2>

            <div class="p-6 pt-8 bg-gradient-to-tl from-moss to-limegreen rounded-md shadow-lg my-8">
                <div class="flex flex-row gap-4">
                    <h1 class="w-8 !text-poisongreen !m-0">1.</h1>
                    <div class="grow-1 w-full">
                        <strong class="text-poisongreen">
                            Maximale Reichweite:
                        </strong>
                        <p class="text-white">
                            Unbegrenzte Sichtbarkeit durch gezielte Werbe-Platzierungen in den relevantesten Kanälen.
                        </p>
                        <hr class="text-white/30 my-4"/>
                    </div>
                </div>
                <div class="flex flex-row gap-4">
                    <h1 class="w-8 !text-poisongreen !m-0">2.</h1>
                    <div class="grow-1 w-full">
                        <strong class="text-poisongreen">
                            Branchenspezifisches Know-How:
                        </strong>
                        <p class="text-white">
                            Wir verstehen die grüne Branche und erreichen proaktiv die besten Talente.
                        </p>
                        <hr class="text-white/30 my-4"/>
                    </div>
                </div>
                <div class="flex flex-row gap-4">
                    <h1 class="w-8 !text-poisongreen !m-0">3.</h1>
                    <div class="grow-1 w-full">
                        <strong class="text-poisongreen">
                            Kostenoptimierte Rekrutierung:
                        </strong>
                        <p class="text-white">
                            Die effizienteste und budgetfreundlichste Lösung für Ihre Mitarbeitersuche.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="appear py-12 overflow-x-hidden container-fluid">
            <div class="container mx-auto">
                <h2 class="text-earth text-center">
                    Was unsere Partner <br class="lg:hidden"/> über uns sagen
                </h2>

                <div class="my-8 mb-16 mx-4 md:mx-0 testimonial-slider">
                    <EmployerTestimonialSlider/>
                </div>
            </div>
        </section>

        <section class="py-12 overflow-x-hidden container-fluid relative">
            <div id="unsere-pakete" style="position: absolute; top: -150px"></div>
            <div class="container mx-auto">
                <h2 class="text-earth text-center">
                    Ihre Optionen
                </h2>

                <div class="my-8 mb-16 mx-4 md:mx-0 pricing-slider">
                    <PricingSlider/>
                </div>
            </div>
        </section>

        <section class="appear py-12">
            <h2 class="text-earth text-left sm:text-center">
                Haben Sie noch Fragen?
            </h2>

            <div class="mx-auto max-w-lg mt-8">
                <div class="flex flex-row gap-10">
                    <div class="w-1/3">
                        <img src="/images/liamquick.jpg" alt="contact" class="aspect-square w-full border-4 bg-moss border-white rounded-full"/>
                    </div>
                    <div class="w-2/3">
                        <p class="font-medium text-xl text-moss inline-flex flex-col gap-1 items-start">
                            Ihr Ansprechpartner:
                            <Stroke mainClass="inline-block text-limegreen h-1 w-auto"/>
                        </p>
                        <p class="font-medium text-limegreen block mt-2">
                            Liam Quick
                        </p>
                        <p>
                            Geschäftsführer
                        </p>
                        <div class="inline-flex flex-col gap-2 mt-4">
                            <a href="tel:+49 221 968 824 08" class="button !py-2 moss !no-underline !text-left !inline-flex items-center gap-2">
                                <Call mainClass="w-4 h-4"/>
                                Sofortkontakt
                            </a>
                            <a href="mailto:hello@green-careers.de" class="button !py-2 moss !no-underline !text-left !inline-flex items-center gap-2 outlined">
                                <Mail mainClass="w-4 h-4"/>
                                E-Mail senden
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>