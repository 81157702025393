<script>
    import Input from "@/components/form/Input.svelte";

    import { api } from "@/services/user"

    let formID = "password"

    let data = {
        current_password: "",
        password_confirmation: "",
        password: "",
    };

    const submit = () => {
        api.updatePassword(data, {formID: formID})
            .then(response => {
                data = {
                    current_password: "",
                    password_confirmation: "",
                    password: "",
                };
            })
    }
</script>

<svelte:head>
  <title>Profile - Green Careers</title>
  <meta name="description" content="Profile"/>
</svelte:head>

<main>
    <Input
        on:enter={() => submit(data)}
        bind:value={data.current_password}
        required={true}
        name="current_password"
        placeholder="Aktuelles Passwort"
        type="password"
        autocomplete="new-password"
        {formID}
        containerClass="w-full my-3"
        inputClass="!bg-sandlight focus:!bg-white"
    />

    <hr class="border-earth/30"/>

    <Input
        on:enter={() => submit(data)}
        bind:value={data.password}
        required={true}
        name="password"
        placeholder="Neues Passwort"
        type="password"
        autocomplete="new-password"
        {formID}
        containerClass="w-full my-3"
        inputClass="!bg-sandlight focus:!bg-white"
    />

    <Input
        on:enter={() => submit(data)}
        bind:value={data.password_confirmation}
        required={true}
        name="password_confirmation"
        placeholder="Neues Passwort bestätigen"
        type="password"
        autocomplete="new-password"
        {formID}
        containerClass="w-full my-3"
        inputClass="!bg-sandlight focus:!bg-white"
    />

    <div class="mt-4">
        <button class="button moss" on:click={() => submit()}>Passwort speichern</button>
    </div>
</main>