<script>
    export let params;

    import { onMount, createEventDispatcher } from "svelte";

    import Search from "@/components/Search.svelte";
    import InformationBox from "@/components/InformationBox.svelte";
    import EmployeeTestimonialSlider from "@/components/EmployeeTestimonialSlider.svelte";
    import PremiumJobSlider from "@/components/PremiumJobSlider.svelte";
    import LogoSlider from "@/components/LogoSlider.svelte";
    import TypeWriter from "@/components/TypeWriter.svelte";
    import { service as jobService, newest } from "@/services/job"
    import { openSearch } from "@/stores/globalsearch"

    const dispatch = createEventDispatcher();
    let loading = true;

    onMount(async () => {
        loading = true;

        dispatch("loaded")
        
        await jobService.newest()

        loading = false;
    })
</script>

<svelte:head>
  <title>Startseite - Green Careers</title>
  <meta name="description" content="Die erste interaktive Karriereplattform für die grüne Branche"/>
</svelte:head>

<main>
    <section class="appear min-h-[var(--app-height)] fill-available md:min-h-[650px] bg-cover bg-[65%] lg:bg-center relative" style="background-image: url('/images/home-1.jpg');">
        <div class="absolute left-0 right-0 bottom-0 top-24 bg-gradient-to-t from-black/90"></div>
        <div class="absolute left-0 right-0 bottom-0 py-12">
            <div class="container mx-auto max-w-4xl">
                <span class="text-poisongreen text-sm lg:text-xl font-medium italic" style="text-shadow: rgba(0,0,0,0.5) 2px 2px 10px;">
                    Die erste interaktive Karriereplattform <br class="lg:hidden"/> für die grüne Branche
                </span>
                <h1 class="text-white text-2xl sm:text-3xl lg:text-6xl">
                    ENTDECKE DEINEN TRAUMJOB
                </h1>
                <h2 class="inline-block relative pl-1 ml-1 font-lemon text-white mt-1 text-2xl lg:text-3xl">
                    <TypeWriter texts={['im GaLaBau', 'im Pflanzenhandel', 'in der Kultivierung', 'in der Landwirtschaft']} mainClass="z-10 relative"/>
                    <div class="bg-limegreen backdrop-blur-md shadow-md absolute -top-1 -left-1 -right-3 -bottom-3 z-0 rotate-[5deg] -skew-y-6 transition-all ease-in-out"></div>
                </h2>
                <div class="mt-12">
                    <Search/>
                </div>
            </div>
        </div>
    </section>

    <section class="appear py-12">
        <div class="container mx-auto max-w-lg">
            <h2 class="text-earth text-xl text-center">
                Unser Karrierenetzwerk umfasst
            </h2>

            <div class="flex flex-row gap-4 mt-4">
                <InformationBox mainClass="rounded-xl shadow-xl p-4 w-1/2 bg-moss text-white" number={window.meta.settings.employee_count} suffix="+" duration={1500}>
                    <p>
                        Fachkräfte aus der grünen Branche
                    </p>
                </InformationBox>
                <InformationBox mainClass="rounded-xl shadow-xl p-4 w-1/2 bg-limegreen text-white" number={window.meta.settings.employer_count} suffix="+" duration={1500}>
                    <p>
                        Arbeitgeber aus der grünen Branche
                    </p>
                </InformationBox>
            </div>
        </div>
    </section>

    <section class="appear py-12 shadow-xl">
        <div class="container mx-auto">
            <h2 class="text-earth text-center">
                Deine Vorteile mit GreenCareers
            </h2>
            
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
                <div class="group flex flex-col justify-between">
                    <div>
                        <h3 class="text-limegreen">
                            Alle grünen Jobangebote
                        </h3>
                        <p class="mt-1">
                            Finde die besten Stellenangebote aus deiner Region und entdecke deinen neuen Traumjob.
                        </p>
                    </div>
                    <div class="group-hover:scale-105 transition-all ease-in-out">
                        <div class="rounded-t-lg shadow-md bg-white mt-6 overflow-hidden w-full aspect-[4/3] relative">
                            <img alt="Stellenangebote Darstellung in der App" src="/images/mockup-1.jpg" class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-[60%] rotate-[27deg] shadow-3xl border-2 rounded-3xl group-hover:scale-105 transition-all ease-in-out group-hover:rotate-[29deg] border-poisongreen"/>
                        </div>
                        <button on:click="{() => openSearch()}" class="button moss !rounded-t-none !block text-center hover:scale-100">
                            Jetzt entdecken
                        </button>
                    </div>
                </div>
                <div class="group flex flex-col justify-between">
                    <div>
                        <h3 class="text-limegreen">
                            Nur für dich passende Jobs
                        </h3>
                        <p class="mt-1">
                            Finde ausschließlich Jobs, die zu deinen Vorstellungen passen. Spare Zeit und Energie bei der Suche nach deinem neuen Arbeitgeber.
                        </p>
                    </div>
                    <div class="group-hover:scale-105 transition-all ease-in-out">
                        <div class="rounded-t-lg shadow-md bg-white mt-6 overflow-hidden w-full aspect-[4/3] relative">
                            <img alt="Stellenangebote Darstellung in der App" src="/images/mockup-2.jpg" class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-[60%] rotate-[27deg] shadow-3xl border-2 rounded-3xl group-hover:scale-105 transition-all ease-in-out group-hover:rotate-[29deg] border-poisongreen"/>
                        </div>
                        <button on:click="{() => openSearch()}" class="button moss !rounded-t-none !block text-center hover:scale-100">
                            Zum Jobfinder
                        </button>
                    </div>
                </div>
                <div class="group flex flex-col justify-between">
                    <div>
                        <h3 class="text-limegreen">
                            In 2 Minuten zu deinem Traumjob
                        </h3>
                        <p class="mt-1">
                            Bewirb dich schnell und einfach ohne lästigen Papierkram - direkt via WhatsApp, Mail oder Telefon.
                        </p>
                    </div>
                    <div class="group-hover:scale-105 transition-all ease-in-out">
                        <div class="rounded-t-lg shadow-md bg-white mt-6 overflow-hidden w-full aspect-[4/3] relative">
                            <img alt="Stellenangebote Darstellung in der App" src="/images/mockup-3.jpg" class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-[60%] rotate-[27deg] shadow-3xl border-2 rounded-3xl group-hover:scale-105 transition-all ease-in-out group-hover:rotate-[29deg] border-poisongreen"/>
                        </div>
                        <button on:click="{() => openSearch()}" class="button moss !rounded-t-none !block text-center hover:scale-100">
                            Direkt loslegen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="appear py-12 overflow-x-hidden">
        <div class="container mx-auto">
            <h2 class="text-earth text-center">
                Aktuelle Stellenanzeigen
            </h2>
            
            <div class="my-8 mb-16 mx-5 md:mx-0 job-preview-slider">
                {#if loading === false && $newest?.data?.length > 0}
                    <PremiumJobSlider jobs={$newest} clickable={true}/>
                {:else}
                    <p class="text-center text-moss">Keine Daten verfügbar</p>
                {/if}
            </div>

            <div class="flex flex-row justify-start md:justify-center">
                <button on:click="{() => openSearch()}" class="button moss md:max-w-lg text-center">
                    Alle Stellen anschauen
                </button>
            </div>
        </div>
    </section>

    <section class="appear py-12 overflow-x-hidden shadow-xl">
        <div class="container mx-auto">
            <h2 class="text-earth text-center">
                Das sagen unsere Nutzer
            </h2>

            <div class="my-8 mb-16 mx-4 md:mx-0 testimonial-slider">
                <EmployeeTestimonialSlider/>
            </div>
        </div>
    </section>

    <section class="appear py-12 overflow-x-hidden">
        <div class="container mx-auto">
            <div class="flex flex-row justify-start md:justify-center">
                <button on:click="{() => openSearch()}" class="button moss md:max-w-lg text-center">
                    Jetzt loslegen
                </button>
            </div>
        </div>
    </section>

    <section class="appear py-12 overflow-x-hidden">
        <div class="container mx-auto">
            <h2 class="text-moss text-center">
                {window.meta.settings.employer_count}+ Arbeitgeber suchen Dich!
            </h2>

            <LogoSlider/>
        </div>
    </section>
</main>