<script>
    import { fly } from "svelte/transition";
    import api from "@/lib/axios";
    import { success, failure } from "@/lib/toast";

    let loading = false;

    const resendEmailVerification = async () => {
        if (loading === false) {
            loading = true;

            await api
                .post("/email/verification-notification")
                .then(() => {
                    success("Sie erhalten in kürze eine E-Mail zur Bestätigung.");
                })
                .catch((error) => {
                    failure(error.message);
                    error.response.data.message && failure(error.response.data.message);
                })
                .finally(() => {
                    loading = false;
                });
        }
    };
</script>

<svelte:head>
  <title>E-Mail bestätigen - Green Careers</title>
  <meta name="description" content="E-Mail bestätigen"/>
</svelte:head>

<main in:fly out:fly>
    <div
        class="bg-earth bg-cover" style="background-image: url('/images/home-1.jpg');"
    >
        <div class="min-h-screen py-24 w-full flex items-center justify-center backdrop-blur-md">
            <div class="w-screen px-6 mt-4 space-y-8 md:mt-0 md:px-2 max-w-lg">
                <div
                    class="p-8 space-y-4 bg-gray-700 backdrop-blur-xl shadow-2xl rounded-md relative bg-sandlight"
                >
                    <img class="w-1/3 mx-auto" src="/images/signage.png" alt="Green Careers"/>
                    <h2 class="text-center text-earth">E-Mail verifizieren</h2>
                    <p class="text-center text-limegreen font-bold">
                        Bitte überprüfen Sie Ihre E-Mails, um Ihre Adresse zu verifizieren. Prüfen Sie bitte auch Ihren Spam-Ordner.
                    </p>
                    <div class="mt-6">
                        <button disabled={loading} on:click={resendEmailVerification} class="button moss">
                            E-Mail erneut senden
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
