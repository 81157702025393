<script>
    export let mode;
    export let formID;
    
    import Upload from '@/components/form/Upload.svelte';
    import Pen from '@/icons/Pen.svelte';
    import Popup from '@/components/Popup.svelte';

    import { jobAdvertisement as tempJobAdvertisement, employer as tempEmployer } from "@/stores/temporary";
    import { getAvailableFileSize } from '@/lib/utils';
    import { success } from '@/lib/toast';

    let uploadPopupOpen = false;

    const getTitleImage = (size = "lg") => {
        if($tempJobAdvertisement?.data?.title_images?.data?.length > 0) {
            return getAvailableFileSize($tempJobAdvertisement?.data?.title_images?.data[0], size);
        }
        else if($tempJobAdvertisement?.data?.employer?.title_images?.data?.length > 0) {
            return getAvailableFileSize($tempEmployer?.data?.title_images?.data[0], size);
        }
        else if($tempJobAdvertisement?.data?.title_images[0]) {
            return `/uploads/${$tempJobAdvertisement?.data?.title_images[0].path}`;
        }
        else {
            return "/images/title_image-placeholder.jpg";
        }
    }
</script>

<div class="relative aspect-[4/2.5] w-full max-w-3xl mx-auto group rounded-3xl bg-no-repeat bg-cover" style="background-image: url({getTitleImage("md-blurred")})">
    <img class="w-full h-full rounded-3xl object-contain object-center" src="{getTitleImage()}" alt="Banner"/>

    {#if mode === "edit"}
        <div class="absolute inset-0 bg-gradient-to-r from-limegreen/50 to-moss/50 rounded-3xl flex items-center justify-center group-hover:opacity-100 opacity-0 transition-opacity duration-300">
            <button on:click={() => uploadPopupOpen = true} class="bg-limegreen text-white flex items-center justify-center rounded-full p-2">
                <Pen mainClass="w-5 h-5"/>
            </button>
        </div>
    {/if}
</div>

<Popup open={uploadPopupOpen} on:close={() => uploadPopupOpen = null}>
    <strong class="text-2xl text-snow">
        Titelbild hochladen
    </strong>

    <div class="bg-sandlight rounded-md mt-4">
        <Upload 
            bind:files={$tempJobAdvertisement.data.title_images}
            type="horizontal"
            title="Laden Sie hier das Titelbild Ihrer Stellenanzeigen hoch"
            message="Das ideale Format beträgt 1200x750 Pixel oder ein Seitenverhältnis von 4/2.5"
            acceptedFileTypes={["image/jpg","image/jpeg","image/png","image/webp"]}
            formID={formID}
            name="title_images"
            maxFileSize="2MB"
            on:uploaded={() => {
                uploadPopupOpen = false
                success("Titelbild erfolgreich hochgeladen")
            }}
        />
    </div>
</Popup>