import { writable } from "svelte/store";
import { success } from "@/lib/toast";
import moment from "moment";
import parsePhoneNumber from 'libphonenumber-js'

export const clickOutside = (node) => {
    const handleClick = (event) => {
        if (node && !node.contains(event.target) && !event.defaultPrevented) {
            node.dispatchEvent(new CustomEvent("clickOutside", node));
        }
    };

    document.addEventListener("mousedown", handleClick, true);

    return {
        destroy() {
            document.removeEventListener("mousedown", handleClick, true);
        },
    };
};

export const syncHeight = (element) => {
    return writable(null, (set) => {
        if (!element) {
            return;
        }
        let resizeObserver = new ResizeObserver(
            () => element && set(element.offsetHeight)
        );
        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
    });
};

export const formatDateRange = (starting_at, ending_at) => {
    starting_at = moment(starting_at);
    ending_at = moment(ending_at);

    if (starting_at && ending_at) {
        if (
            starting_at.format("DD.MM.YYYY") != ending_at.format("DD.MM.YYYY")
        ) {
            return (
                starting_at.format("DD.MM.YYYY") +
                " - " +
                ending_at.format("DD.MM.YYYY") +
                " | " +
                starting_at.format("HH:mm") +
                " - " +
                ending_at.format("HH:mm") +
                " Uhr"
            );
        } else {
            return (
                starting_at.format("DD.MM.YYYY") +
                " | " +
                starting_at.format("HH:mm") +
                " - " +
                ending_at.format("HH:mm") +
                " Uhr"
            );
        }
    } else if (starting_at) {
        return (
            starting_at.format("DD.MM.YYYY") +
            " - " +
            starting_at.format("DD.MM.YYYY")
        );
    }
};

export const getVerticalScrollPercentage = () => {
    return (
        (document.documentElement.scrollTop /
            (document.documentElement.scrollHeight -
                document.documentElement.clientHeight)) *
        100
    );
};

export const getVerticalScroll = () => {
    return document.scrollTop + document.clientHeight;
};

export const readableFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

    let unit = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++unit;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        unit < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[unit];
};

export const slugify = (string) => {
    return string
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
};

export const toCurrency = (value, currency, minimumFractionDigits = 2) => {
    if (!currency) {
        currency = "USD";
    }
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("en-EN", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: minimumFractionDigits,
    });
    return formatter.format(value);
};

export const countLines = (target) => {
    var style = window.getComputedStyle(target, null);
    var height = parseInt(style.getPropertyValue("height"));
    var font_size = parseInt(style.getPropertyValue("font-size"));
    var line_height = parseInt(style.getPropertyValue("line-height"));
    var box_sizing = style.getPropertyValue("box-sizing");

    if (isNaN(line_height)) line_height = font_size * 1.2;

    if (box_sizing == "border-box") {
        var padding_top = parseInt(style.getPropertyValue("padding-top"));
        var padding_bottom = parseInt(style.getPropertyValue("padding-bottom"));
        var border_top = parseInt(style.getPropertyValue("border-top-width"));
        var border_bottom = parseInt(
            style.getPropertyValue("border-bottom-width")
        );
        height =
            height - padding_top - padding_bottom - border_top - border_bottom;
    }
    var lines = Math.ceil(height / line_height);
    return lines;
};

export const zeroPad = (num, places) => String(num).padStart(places, "0");

export const replaceQueryParams = (params) => {
    _.forEach(params, (param, key) => {
        let url = new URL(window.location.href);

        param != ""
            ? url.searchParams.set(key, param)
            : url.searchParams.delete(key);

        window.history.replaceState(null, "", url);
    });
};

export const removeQueryParams = (params) => {
    _.forEach(params, (key) => {
        let url = new URL(window.location.href);

        url.searchParams.delete(key);
        window.history.replaceState(null, "", url);
    });
};

export const getQueryParameterByName = (name, fallback) => {
    return new URLSearchParams(window.location.search).get(name) ?? fallback;
};

export const textEllipsis = (
    str,
    maxLength,
    { side = "end", ellipsis = "..." } = {}
) => {
    if (str.length > maxLength) {
        switch (side) {
            case "start":
                return ellipsis + str.slice(-(maxLength - ellipsis.length));
            case "end":
            default:
                return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
    }
    return str;
};

export const uniqueArrayOfObject = (array, keyToBeUnique) => {
    return array.filter(
        (x, xi) =>
            !array
                .slice(xi + 1)
                .some((y) => y[keyToBeUnique] === x[keyToBeUnique])
    );
};

export const structuredClone = function structuredClone(objectToClone) {
    const stringified = JSON.stringify(objectToClone);
    const parsed = JSON.parse(stringified);
    return parsed;
};

export const formatDate = (date, format = "ll") => {
    return moment(date).format(format);
};

export const formatDateTime = (date) => {
    return moment(date).format("lll");
};

export const copyToClipboard = (event) => {
    navigator.clipboard.writeText(event.srcElement.innerHTML);
    success("copied to clipboard");
};

export var deepDiffMapper = (function () {
    return {
        VALUE_CREATED: "created",
        VALUE_UPDATED: "updated",
        VALUE_DELETED: "deleted",
        VALUE_UNCHANGED: "unchanged",
        map: function (obj1, obj2) {
            if (this.isFunction(obj1) || this.isFunction(obj2)) {
                throw "Invalid argument. Function given, object expected.";
            }
            if (this.isValue(obj1) || this.isValue(obj2)) {
                return {
                    type: this.compareValues(obj1, obj2),
                    data: obj1 === undefined ? obj2 : obj1,
                };
            }

            var diff = {};
            for (var key in obj1) {
                if (this.isFunction(obj1[key])) {
                    continue;
                }

                var value2 = undefined;
                if (obj2[key] !== undefined) {
                    value2 = obj2[key];
                }

                diff[key] = this.map(obj1[key], value2);
            }
            for (var key in obj2) {
                if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
                    continue;
                }

                diff[key] = this.map(undefined, obj2[key]);
            }

            return diff;
        },
        compareValues: function (value1, value2) {
            if (value1 === value2) {
                return this.VALUE_UNCHANGED;
            }
            if (
                this.isDate(value1) &&
                this.isDate(value2) &&
                value1.getTime() === value2.getTime()
            ) {
                return this.VALUE_UNCHANGED;
            }
            if (value1 === undefined) {
                return this.VALUE_CREATED;
            }
            if (value2 === undefined) {
                return this.VALUE_DELETED;
            }
            return this.VALUE_UPDATED;
        },
        isFunction: function (x) {
            return Object.prototype.toString.call(x) === "[object Function]";
        },
        isArray: function (x) {
            return Object.prototype.toString.call(x) === "[object Array]";
        },
        isDate: function (x) {
            return Object.prototype.toString.call(x) === "[object Date]";
        },
        isObject: function (x) {
            return Object.prototype.toString.call(x) === "[object Object]";
        },
        isValue: function (x) {
            return !this.isObject(x) && !this.isArray(x);
        },
    };
})();

export const objectToArray = (obj) => {
    let array = [];

    Object.keys(obj).forEach((key) => {
        array.push({
            value: key,
            label: obj[key],
        });
    });

    return array;
};

export const translate = (attribute) => {
    if (typeof attribute === "object" && !Array.isArray(attribute) && attribute !== null) {
        // object
        if (attribute[get(locale)]) {
            return attribute[get(locale)]
        } else {
            return attribute['de']
        }
    } else if (Array.isArray(attribute) || attribute === null) {
        // array or null
        return null
    } else {
        // if none of all just return the value
        return attribute
    }
}

export const getRandomInt = (max = 1000) => {
    return Math.floor(Math.random() * max);
}

export const difference = (object, base) => {
    return _.transform(object, (result, value, key) => {
        if (!_.isEqual(value, base[key])) {
            result[key] = (_.isObject(value) && _.isObject(base[key])) ? difference(value, base[key]) : value;
        }
    });
}

export const newlinesToArray = (string) => {
    return string?.split(/\n|\r\n/g).map((v) => v) ?? []
}

export const newlinesToBreak = (string) => {
    return string?.split(/\n|\r\n/g).map((v) => v).join("</br>") ?? ""
}

export const getAvailableFileSize = (file, maxSize="thumb") => {
    const sizes = ["thumb", "sm", "md", "md-blurred", "lg", "original"];
    const maxIndex = sizes.indexOf(maxSize);

    for (let i = maxIndex; i >= 0; i--) {
        const size = sizes[i];
        if (file[size]) {
            return file[size];
        }
    }

    return file["url"];
}

export const stickyUntilElement = (navbarID, footerID) => {
    const navbar = document.getElementById(navbarID);
    const footer = document.getElementById(footerID);
    const footerTop = footer.getBoundingClientRect().top;

    if (footerTop <= window.innerHeight) {
        navbar.style.position = 'absolute';
        navbar.style.bottom = `${footer.offsetHeight}px`;
    } else {
        navbar.style.position = 'fixed';
        navbar.style.bottom = '0';
    }
}

export const formattedPhoneNumber = (phoneNumber, format="INTERNATIONAL") => {
    const formatted = parsePhoneNumber(phoneNumber)
    
    if (formatted.isValid()) {
        return formatted.format(format);    
    }

    return phoneNumber
}